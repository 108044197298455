import { Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { getOrganization, organizationApis } from '../api/api';
import MainPage from '../core/MainPage';
import { useEffect, useState } from 'react';
import Employees from './Employees';
import { useParams } from 'react-router';
import Clients from './Clients';
import { useHistory } from 'react-router-dom';
import ItemDrawer from '../core/ItemDrawer';
import OrganizationFormFunction from './OrganizationFormFunction';
import Routes from './Routes';
import { useDispatch } from 'react-redux';
import { setMarkers } from '../app/markerReducer';
import Stocks from './Stocks';
import ErrorHandler from '../util/ErrorHandler';
import TabSet from '../core/TabSet';
import Orders from './Orders';
import Sent from './Sent';
import ClientOrders from './ClientOrders';
import Received from './Received';
import Targets from './Targets';
import OrganizationAnalytics from './Analytics';
import PageDescriptions from '../core/PageDescriptions';
import Visits from './Visits';

const Organization = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { id } = useParams();

  const [Organization, setOrganization] = useState(undefined);

  const [ShowDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    fetchOrganization();
  }, [id]);

  const fetchOrganization = () => {
    getOrganization(id)
      .then((response) => {
        setOrganization(response.data);
        dispatch(
          setMarkers([
            {
              ...response.data.location,
              name: response.data.name,
              icon: response.data.level.id,
            },
          ])
        );
      })
      .catch((error) => {
        ErrorHandler(error, 'Failed to get organization !', history);
      });
  };

  const addressToString = (location) => {
    if (
      location.addressLine1 ||
      location.addressLine2 ||
      location.addressLine3 ||
      location.addressLine4
    ) {
      return `${location.addressLine1} ${location.addressLine2} ${location.addressLine3} ${location.addressLine4}`.replaceAll(
        'null',
        ''
      );
    } else {
      return 'Not Available';
    }
  };

  return (
    <MainPage
      headerProps={{
        title: Organization ? Organization.name : 'Loading ...',
        subTitle: Organization && Organization.level.name,
        extra: [
          <Button icon={<MoreOutlined />} onClick={() => setShowDrawer(true)}>
            {'MORE'}
          </Button>,
        ],
      }}
      headerContent={
        Organization && (
          <PageDescriptions
            items={[
              {
                label: 'Assigned To',
                value: Organization.employeeClients[0]?.parent
                  ? `${Organization.employeeClients[0]?.parent.user.firstName} ${Organization.employeeClients[0]?.parent.user.lastName}`
                  : 'Not Assigned',
                url: Organization.employeeClients[0]?.parent
                  ? `/employee/${Organization.employeeClients[0]?.parent.id}`
                  : undefined,
              },
              {
                label: 'Parent',
                value:
                  Organization.parent &&
                  Organization.parent.length > 0 &&
                  Organization.parent[0].parent.name,
                url:
                  Organization.parent &&
                  Organization.parent.length > 0 &&
                  `/organization/${Organization.parent[0].parent.id}`,
                // Organization.parent[0].parent.name
              },
              {
                label: 'Email',
                value: Organization.contact.email,
              },
              {
                label: 'Phone Number 1',
                value: Organization.contact.phoneNumber1,
              },
              {
                label: 'Phone Number 2',
                value: Organization.contact.phoneNumber2,
              },
              {
                label: 'Address',
                value: addressToString(Organization.location),
              },
              {
                label: 'Color',
                value: Organization.color,
                render: (value) => (
                  <div
                    style={{
                      height: 25,
                      width: 25,
                      backgroundColor: value,
                      borderRadius: '5px',
                    }}
                  ></div>
                ),
              },
            ]}
          />

          //   {/*{Organization.employeeClients.length > 0 && <Descriptions.Item*/}
          //   {/*    label={'Assigned To'}><Button*/}
          //   {/*        type={'link'}*/}
          //   {/*        size={'small'}*/}
          //   {/*        onClick={() => history.push(`/employee/${Organization.employeeClients[0].parent.id}`)}>*/}
          //   {/*        {Organization.employeeClients[0].parent.user.firstName + ' ' + Organization.employeeClients[0].parent.user.lastName}*/}
          //   {/*    </Button></Descriptions.Item>}*/}
        )
      }
      item={Organization}
    >
      {Organization && (
        <ItemDrawer
          old={true}
          item={Organization}
          apis={organizationApis}
          itemName={'organization'}
          restToFormMapper={(data) => ({
            ...data,
            location: data.location && {
              ...data.location,
              coordinates: data.location && {
                lat: data.location.lat,
                lng: data.location.lng,
              },
            },
            color: data.color ? data.color : '#006B76',
          })}
          formToRestMapper={(data, old) => ({
            ...data,
            color: !data.color
              ? old
                ? old.color
                : '#006B76'
              : data.color.hex || data.color,
            parent: [],
            location: {
              ...data.location,
              lat: parseFloat(data.location.coordinates.lat),
              lng: parseFloat(data.location.coordinates.lng),
            },
          })}
          onClose={(updated) => {
            updated && fetchOrganization();
            setShowDrawer(false);
          }}
          visible={ShowDrawer}
          drawerFormUpdatable={true}
        >
          {OrganizationFormFunction(true)}
        </ItemDrawer>
      )}

      {Organization && (
        <TabSet
          routes={{
            default: 'clients',
            routes: [
              {
                permission: 'organization_client:list',
                name: 'Clients',
                dataIndex: 'clients',
                component: (visible) => (
                  <Clients visible={visible} parent={Organization} />
                ),
              },
              {
                permission: 'employee:list',
                name: 'Employees',
                dataIndex: 'employees',
                component: (visible) => (
                  <Employees visible={visible} parent={Organization} />
                ),
              },
              {
                permission: 'organization_route:list',
                name: 'Routes',
                dataIndex: 'routes',
                component: (visible) => (
                  <Routes visible={visible} parent={Organization} />
                ),
              },
              {
                permission: 'stock:list',
                name: 'Stocks',
                dataIndex: 'stocks',
                component: (visible) => (
                  <Stocks visible={visible} parent={Organization} />
                ),
              },
              Organization.parent.length > 0 && {
                permission: 'stock_transfer:list',
                name: 'Orders',
                dataIndex: 'orders',
                component: (visible) => (
                  <Orders visible={visible} parent={Organization} />
                ),
              },
              {
                permission: 'delivery:list',
                name: 'Received',
                dataIndex: 'received',
                component: (visible) => (
                  <Received visible={visible} parent={Organization} />
                ),
              },
              {
                permission: 'stock_transfer:list',
                name: 'Client Orders',
                dataIndex: 'client-orders',
                component: (visible) => (
                  <ClientOrders visible={visible} parent={Organization} />
                ),
              },
              {
                permission: 'delivery:list',
                name: 'Sent',
                dataIndex: 'sent',
                component: (visible) => (
                  <Sent visible={visible} parent={Organization} />
                ),
              },
              {
                permission: 'organization:list',
                name: 'Analytics',
                dataIndex: 'analytics',
                component: (visible) => (
                  <OrganizationAnalytics
                    visible={visible}
                    parent={Organization}
                  />
                ),
              },
              {
                permission: 'target:list',
                name: 'Targets',
                dataIndex: 'targets',
                component: (visible) => (
                  <Targets visible={visible} parent={Organization} />
                ),
              },
              {
                permission: 'visit:list',
                name: 'Visits',
                dataIndex: 'visits',
                component: (visible) => (
                  <Visits visible={visible} parent={Organization} />
                ),
              },
            ],
          }}
        />
      )}
    </MainPage>
  );
};

export default Organization;
