import Map from "../Map";

const DataMapChart = ({heatMapData}) => {
    return <div
        style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'red',
        }}
    >
        <Map
            mapContainerStyle={{
                width: '100%',
                height: 550,
            }}
            heatMapData={heatMapData}
        />
    </div>
}

export default DataMapChart